@import '../../styles/colors.scss';

.layout_container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .layout_children_container {
    .icon_heading_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        height: 55px;
        width: 70px;
      }
    }
    width: 500px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    padding: 20px;
    height: auto;
  }
}

@media screen and (max-width: 550px) {
  .layout_container {
    height: 100vh;
    width: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    .layout_children_container {
      width: auto;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
      padding: 20px;
      height: auto;
    }
  }
}
