@import '../../styles/colors.scss';

.main_layout_container {
  min-height: 100vh;
  height: auto;
  width: 100%;

  .children_wrapper {
    margin-top: 80px;
    // width: 100%;
    padding: 12px 35px;
  }
}
