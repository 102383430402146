@import './styles/colors.scss';

.main-container {
  width: 100%;
  height: 100vh;
}

.error-msg {
  color: $errorRed;
  font-size: 11px;
}

.mobile-display {
  display: none;
}

.desktop-display {
  display: block;
}

@media screen and (max-width: 550) {
  .mobile-display {
    display: block;
  }

  .desktop-display {
    display: none;
  }
}
