@import '../../styles/colors.scss';

.primary-btn {
  height: 48px;
  border-radius: 6px !important;
  width: 100%;
  background-color: $primarySalmon !important;
  text-transform: none !important;
  font-size: 16px;
}

.primary-btn:disabled {
  background-color: lightgray !important;
}

.primary-btn:hover {
  background-color: $primarySalmon;
}

.secondary-btn {
  height: 48px;
  border-radius: 6px !important;
  width: 100%;
  background-color: $offWhite !important;
  text-transform: none !important;
  font-size: 16px !important;
  color: $primarySalmon;
  border: 1px solid $primarySalmon;
}
