@import '../../styles/colors.scss';

.header_container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 40px;
  padding: 10px 35px;
  background-color: #fff;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: start;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  .icon_heading_wrapper {
    display: flex;
    gap: 25px;
    align-items: center;

    img {
      height: 30px;
      width: 30px;
    }

    h2 {
      font-size: 16px;
      font-weight: 600;
      margin: 0px;
      color: $black;
    }
  }
}
