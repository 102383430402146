.payment-success-wrapper {
  border: 1px solid lightgrey;
  border-radius: 6px;
  padding: 2rem 8rem;
  text-align: center;

  h5 {
    font-size: 20px;
    font-weight: 500;
  }
}

.check-your-plan-wrapper {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 50%;
  }
}
