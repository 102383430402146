@import "../../../styles/colors.scss";

.feature-table-wrapper {
  border: 1px solid lightgrey;
  border-radius: 6px;
  padding: 2rem 8rem;
  text-align: center;

  .table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    margin-top: 12px;
    overflow-x: auto;

    .pricing_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      height: 260px;
      width: 160px;
      box-shadow: rgba(57, 95, 133, 0.2) 0px 8px 24px;
      border-radius: 6px;
      padding: 8px 12px;
      background: rgb(220, 188, 25);
      background: linear-gradient(
        0deg,
        rgba(220, 188, 25, 1) 3%,
        rgba(174, 80, 140, 0.32834383753501406) 99%
      );

      .plan_name {
        font-size: 20px;
        font-weight: 600;
        border-bottom: 1px solid $black;
        padding: 6px;
      }
    }

    .pricing_wrapper:hover {
      background: rgb(220, 188, 25);
      background: linear-gradient(
        0deg,
        rgba(220, 188, 25, 1) 4%,
        rgba(174, 80, 140, 0.32834383753501406) 67%
      );
    }
  }
}

@media screen and (max-width: 720px) {
  .feature-table-wrapper {
    padding: 25px;

    .table-container {
      justify-content: start;
    }
  }
}
