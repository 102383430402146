@import '../../../styles/colors.scss';

.membership_details_container {
  border: 1px solid lightgray;
  border-radius: 6px;
  padding: 2rem 8rem;

  h2 {
    font-size: 24px;
    font-family: sans-serif;
    font-weight: 600;
    letter-spacing: 0.1;
    color: $black;
  }

  .details_wrapper {
    margin: 20px 0px;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;

    .card_container {
      flex: 1 1 300px;
      height: 80px;
      border-radius: 12px;
      background-color: $yellow;
      padding: 1rem;
      color: $black;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;

      p {
        font-weight: 600;
        margin-left: 6px;
      }
    }
  }
}

.renew-btn-wrapper {
  padding: 10px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: end;
  width: 100%;
  background-color: $white;

  button {
    width: 250px;
    margin-right: 50px;
    height: 48px;
  }
}

@media screen and (max-width: 720px) {
  .membership_details_container {
    padding: 1rem;

    h2 {
      font-size: 18px;
    }

    .details_wrapper {
      margin: 20px 0px;
      display: flex;
      gap: 30px;
      flex-wrap: wrap;

      .card_container {
        flex: 1 1 300px;
        height: 80px;
        border-radius: 12px;
        background-color: $yellow;
        padding: 1rem;
      }
    }
  }

  .renew-btn-wrapper {
    padding: 10px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    // width: 100%;
    background-color: $white;

    button {
      width: 100%;
      margin-right: 0px;
      height: 48px;
    }
  }
}
